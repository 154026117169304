import { Input } from '@monorepo/components'
import { Field, Formik, setNestedObjectValues } from 'formik'
import Select, { MultiValue, SingleValue } from 'react-select'
import React, { ChangeEvent, useState } from 'react'
import { cloneDeep } from 'lodash'
import * as S from '../styles'
import * as Yup from 'yup'
import { DealerForm as IDealerForm } from '../../../templates/TeamMembers'
import {
  Checkbox,
  FormControlLabel,
  Box,
  Typography,
  IconButton,
} from '@mui/material'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { pink } from '@mui/material/colors'
import {
  DealerHelper,
  isNumeric,
  ReactSelectOptionType,
  ChannelHelper,
  isNumber,
  isObject,
  emptyReactSelectOption,
  useChannel,
} from '@monorepo/infra'
import { CoopAllowance } from '@monorepo/interfaces'
import { colors } from '@monorepo/theme'

export type InitialValuesParsed = {
  firstName: string
  lastName: string
  email: string
  channels: ReactSelectOptionType[] | null
  types: ReactSelectOptionType[] | null
  status: ReactSelectOptionType
  hasCoop: boolean
  userId: string
  dealerId: string
  coopAllowances?: CoopAllowance[]
}

type DealerFormProps = {
  close: () => void
  actionForm: (data: IDealerForm) => void
  initialValues?: InitialValuesParsed
  error: string
  masterDealerId: string
  availableChannelOptions: ReactSelectOptionType[]
  isPrincipal?: boolean
}

const TeamMemberSchema = Yup.object().shape({
  firstName: Yup.string().required('Please enter with first name.'),
  lastName: Yup.string().required('Please enter with last name.'),
  email: Yup.string().required('Please enter with email address.'),
})

const coopAllowanceCapabilityOptions =
  DealerHelper.getCoopAllowanceCapabilityOptions()

const DealerForm: React.FC<DealerFormProps> = ({
  initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    channels: null,
    types: null,
    status: emptyReactSelectOption,
    hasCoop: false,
    userId: '',
    dealerId: '',
    coopAllowances: undefined,
  },
  actionForm,
  close,
  error,
  masterDealerId,
  availableChannelOptions,
  isPrincipal,
}) => {
  const dealerStatus = [
    {
      value: '/dealer',
      label: 'Member',
    },
    {
      value: '/dealerAdmin',
      label: 'Admin',
    },
  ]
  const channel = useChannel()
  const [hasCoopAllowance, setHasCoopAllowance] = useState<boolean>(
    Array.isArray(initialValues?.coopAllowances) &&
      initialValues.coopAllowances.length > 0
  )
  const [currentCoopAllowance, setCurrentCoopAllowance] = useState<
    CoopAllowance | undefined
  >(
    Array.isArray(initialValues?.coopAllowances) &&
      initialValues.coopAllowances.length
      ? initialValues.coopAllowances[0]
      : undefined
  )

  const isEditMode = !!initialValues.email

  const handleChannelsChange = (
    newValue: MultiValue<ReactSelectOptionType>,
    values: InitialValuesParsed,
    setFieldValue?: (
      field: string,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) => {
    if (
      !newValue ||
      !Array.isArray(newValue) ||
      !newValue.length ||
      !Array.isArray(values.coopAllowances)
    ) {
      if (values.coopAllowances) {
        setFieldValue?.('coopAllowances', undefined)
      }

      if (currentCoopAllowance) {
        setCurrentCoopAllowance(undefined)
      }

      return
    }

    const coopAllowances = cloneDeep(values.coopAllowances)

    for (let i = coopAllowances.length - 1; i >= 0; --i) {
      const masterDealer = ChannelHelper.splitMasterDealerId(
        coopAllowances[i].masterDealerId
      )

      if (
        !masterDealer?.channel ||
        !newValue.find(
          (option) =>
            option.value.toLowerCase() === masterDealer.channel.toLowerCase()
        )
      ) {
        coopAllowances.splice(i, 1)
      }
    }

    setFieldValue?.(
      'coopAllowances',
      coopAllowances.length ? coopAllowances : undefined
    )

    if (currentCoopAllowance) {
      if (coopAllowances.length) {
        if (
          !coopAllowances.find(
            (item) =>
              item.masterDealerId === currentCoopAllowance.masterDealerId
          )
        ) {
          setCurrentCoopAllowance(coopAllowances[0])
        }
      } else {
        setCurrentCoopAllowance(undefined)
      }
    }
  }

  const getCurrentCoopAllowanceChannelOption = () => {
    const masterDealer = ChannelHelper.splitMasterDealerId(
      currentCoopAllowance?.masterDealerId
    )

    return !!masterDealer?.channel
      ? ChannelHelper.getChannelOptions().find(
          (item) =>
            item.value.toLowerCase() === masterDealer.channel.toLowerCase()
        )
      : emptyReactSelectOption
  }

  const getCurrentCoopAllowanceCapability = () =>
    isNumber(currentCoopAllowance?.capability)
      ? DealerHelper.getCoopAllowanceCapabilityOptions().find(
          (item) => item.value === currentCoopAllowance?.capability?.toString()
        )
      : emptyReactSelectOption

  const handleHasCoopChange = (
    newValue: boolean,
    setFieldValue?: (
      field: string,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) => {
    setFieldValue?.('hasCoop', newValue)

    if (!newValue) {
      return
    }

    setHasCoopAllowance(false)
    setFieldValue?.('coopAllowances', undefined)
    setCurrentCoopAllowance(undefined)
  }

  const handleHasCoopAllowanceChange = (
    newValue: boolean,
    setFieldValue?: (
      field: string,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) => {
    setHasCoopAllowance(newValue)

    if (newValue) {
      setFieldValue?.('hasCoop', false)
    } else {
      setFieldValue?.('coopAllowances', undefined)
      setCurrentCoopAllowance(undefined)
    }
  }

  const handleCoopAllowanceChannelChange = (
    newValue:
      | MultiValue<ReactSelectOptionType>
      | SingleValue<ReactSelectOptionType>,
    values: InitialValuesParsed,
    setFieldValue?: (
      field: string,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) => {
    if (!isObject(newValue)) {
      return
    }

    const _masterDealerId = ChannelHelper.getMasterDealerId(
      masterDealerId,
      (
        (newValue as SingleValue<ReactSelectOptionType>)?.value as string
      )?.toUpperCase()
    )

    const coopAllowances =
      Array.isArray(values.coopAllowances) && values.coopAllowances.length
        ? cloneDeep(values.coopAllowances)
        : []

    let coopAllowance = DealerHelper.getCoopAlloance(
      coopAllowances,
      _masterDealerId
    )

    if (!coopAllowance) {
      coopAllowance = {
        masterDealerId: _masterDealerId,
        allowance: undefined,
        capability: undefined,
      }

      coopAllowances.push(coopAllowance)
      setFieldValue?.('coopAllowances', coopAllowances)
    }

    setCurrentCoopAllowance(coopAllowance)
  }

  const handleCoopAllowanceChange = (
    newValue: string,
    values: InitialValuesParsed,
    setFieldValue?: (
      field: string,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) => {
    const coopAllowances = cloneDeep(values.coopAllowances)

    let coopAllowance = DealerHelper.getCoopAlloance(
      coopAllowances,
      currentCoopAllowance?.masterDealerId
    )

    if (isNumeric(newValue)) {
      const _newValue = parseFloat(newValue)

      if (coopAllowance) {
        coopAllowance.allowance = _newValue
      } else {
        coopAllowance = {
          masterDealerId:
            currentCoopAllowance?.masterDealerId?.toUpperCase() as string,
          allowance: _newValue,
          capability: currentCoopAllowance?.capability,
        }
      }

      setCurrentCoopAllowance(coopAllowance)
    } else {
      if (coopAllowance) {
        coopAllowance.allowance = undefined
        coopAllowance.capability = undefined
      }

      setCurrentCoopAllowance(coopAllowance)
    }

    setFieldValue?.('coopAllowances', coopAllowances)
  }

  const handleCoopAllowanceCapabilityChange = (
    newValue:
      | MultiValue<ReactSelectOptionType>
      | SingleValue<ReactSelectOptionType>,
    values: InitialValuesParsed,
    setFieldValue?: (
      field: string,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) => {
    const coopAllowances = cloneDeep(values.coopAllowances)

    let coopAllowance = DealerHelper.getCoopAlloance(
      coopAllowances,
      currentCoopAllowance?.masterDealerId
    )

    if (isObject(newValue)) {
      const _newValue = parseInt(
        (newValue as SingleValue<ReactSelectOptionType>)?.value as string,
        10
      )

      if (coopAllowance) {
        coopAllowance.capability = _newValue
      } else {
        coopAllowance = {
          masterDealerId:
            currentCoopAllowance?.masterDealerId?.toUpperCase() as string,
          allowance: currentCoopAllowance?.allowance,
          capability: _newValue,
        }
      }

      setCurrentCoopAllowance(coopAllowance)
    } else {
      if (coopAllowance) {
        coopAllowance.allowance = undefined
        coopAllowance.capability = undefined
      }

      setCurrentCoopAllowance(coopAllowance)
    }

    setFieldValue?.('coopAllowances', coopAllowances)
  }

  const handleDeleteCoopAllowance = (
    values: InitialValuesParsed,
    setFieldValue?: (
      field: string,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) => {
    if (
      !currentCoopAllowance ||
      !Array.isArray(values.coopAllowances) ||
      !values.coopAllowances.length
    ) {
      return
    }

    const coopAllowances = DealerHelper.deleteCoopAlloance(
      cloneDeep(values.coopAllowances),
      currentCoopAllowance.masterDealerId
    )

    setFieldValue?.('coopAllowances', coopAllowances)

    setCurrentCoopAllowance(
      coopAllowances?.length ? coopAllowances[0] : undefined
    )
  }

  return (
    <>
      {error !== '' && <S.ErrorMessage>{error}</S.ErrorMessage>}
      <Formik
        initialValues={initialValues}
        validationSchema={TeamMemberSchema}
        validateOnBlur={true}
        validateOnChange={true}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false)
          const valuesParsed = {
            ...values,
            email: values?.email?.trim(),
            channels: values?.channels?.map((data) => data.value) || [''],
            types: values?.types?.map((data) => data.value) || [''],
          }
          actionForm(valuesParsed)
        }}
      >
        {({
          errors,
          touched,
          values,
          setFieldValue,
          setFieldTouched,
          validateForm,
          submitForm,
          setTouched,
        }) => (
          <S.DialogForm>
            <Field
              id="firstName"
              name="firstName"
              component={Input}
              value={values.firstName}
              invalid={touched.firstName && errors.firstName}
              invalidMessage={errors.firstName}
              label="First name"
              onBlur={() => setFieldTouched('firstName')}
              onChange={(option: { target: { value: string } }) => {
                setFieldValue('firstName', option.target.value)
              }}
            />

            <Field
              id="lastName"
              name="lastName"
              component={Input}
              value={values.lastName}
              invalid={touched.lastName && errors.lastName}
              invalidMessage={errors.lastName}
              label="Last name"
              onBlur={() => {
                setFieldTouched('lastName')
              }}
              onChange={(option: { target: { value: string } }) => {
                setFieldValue('lastName', option.target.value)
              }}
            />

            <Field
              id="email"
              name="email"
              component={Input}
              value={values.email}
              invalid={touched.email && errors.email}
              invalidMessage={errors.email}
              label="Email Address"
              disabled={isEditMode}
              onBlur={() => {
                setFieldTouched('email')
              }}
              onChange={(option: { target: { value: string } }) => {
                setFieldValue('email', option.target.value)
              }}
            />

            <Select
              name="channels"
              placeholder="Channel(s)"
              isMulti
              options={availableChannelOptions}
              value={values.channels}
              onChange={(newValue) => {
                setFieldValue('channels', newValue)
                handleChannelsChange(newValue, values, setFieldValue)
              }}
              components={{
                IndicatorSeparator: () => null,
              }}
              styles={S.MultiSelectStyle}
            />

            {/* <Select
            name="types"
            placeholder="Type(s)"
            isMulti
            options={DealerHelper.dealerTypes}
            value={DealerHelper.dealerTypes.filter(
              ({ value }: { value: string }) => {
                return values.types?.some((item) => {
                  return item.value === value
                })
              }
            )}
            onChange={(newValue) => setFieldValue('types', newValue)}
            components={{
              IndicatorSeparator: () => null,
            }}
            styles={S.MultiSelectStyle}
          /> */}

            <Select
              name="status"
              placeholder="Status"
              options={dealerStatus}
              components={{
                IndicatorSeparator: () => null,
              }}
              value={values.status || ''}
              onChange={(newValue) => setFieldValue('status', newValue)}
              styles={S.MultiSelectStyle}
              isDisabled={isPrincipal}
            />

            {channel.hasCoop || isEditMode ? (
              <>
                <S.CheckboxCoopContainer>
                  <FormControlLabel
                    label="Unlimited Co-Op Access"
                    sx={{
                      color: pink[800],
                      '& .MuiFormControlLabel-label': { fontWeight: 600 },
                    }}
                    control={
                      <Checkbox
                        id="hasCoop"
                        name="hasCoop"
                        checked={values.hasCoop}
                        onChange={(_, checked) =>
                          handleHasCoopChange(checked, setFieldValue)
                        }
                        sx={{
                          color: pink[800],
                          '&.Mui-checked': { color: pink[600] },
                          '& .MuiSvgIcon-root': { fontSize: 28 },
                        }}
                        disabled={isPrincipal}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Custom Co-Op Allowance"
                    sx={{
                      color: pink[800],
                      '& .MuiFormControlLabel-label': { fontWeight: 600 },
                    }}
                    control={
                      <Checkbox
                        id="hasCoopAllowance"
                        name="hasCoopAllowance"
                        checked={hasCoopAllowance}
                        onChange={(_, checked) =>
                          handleHasCoopAllowanceChange(checked, setFieldValue)
                        }
                        sx={{
                          color: pink[800],
                          '&.Mui-checked': { color: pink[600] },
                          '& .MuiSvgIcon-root': { fontSize: 28 },
                        }}
                        disabled={isPrincipal}
                      />
                    }
                  />
                </S.CheckboxCoopContainer>
                <Box
                  component="fieldset"
                  sx={{
                    display: hasCoopAllowance ? 'grid' : 'none',
                    gridTemplateColumns: '1fr 1fr 1fr 50px',
                    gap: '10px 20px',
                    borderColor: colors.mediumGrey,
                    borderStyle: 'solid',
                    borderWidth: 1,
                    padding: '16px',
                  }}
                >
                  <Typography
                    component="legend"
                    sx={{
                      padding: '0 5px',
                      fontFamily: 'PFDINText',
                      fontWeight: 600,
                      fontSize: '15px',
                      color: colors.mediumGrey,
                    }}
                  >
                    Co-Op allowances
                  </Typography>

                  <Select
                    name="coopAllowanceChannel"
                    placeholder="Channel"
                    options={values.channels as ReactSelectOptionType[]}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    value={getCurrentCoopAllowanceChannelOption()}
                    onChange={(newValue) =>
                      handleCoopAllowanceChannelChange(
                        newValue,
                        values,
                        setFieldValue
                      )
                    }
                    styles={S.SelectStyle}
                  />

                  <Field
                    id="coopAllowance"
                    name="coopAllowance"
                    component={Input}
                    value={currentCoopAllowance?.allowance || ''}
                    invalid={touched.coopAllowances && errors.coopAllowances}
                    invalidMessage={errors.coopAllowances}
                    label="Co-Op allowance"
                    onBlur={() => {
                      setFieldTouched('coopAllowance')
                    }}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleCoopAllowanceChange(
                        e.target.value,
                        values,
                        setFieldValue
                      )
                    }
                  />

                  <Select
                    name="coopAllowanceCapability"
                    placeholder="Co-Op allowance capability"
                    options={coopAllowanceCapabilityOptions}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    value={getCurrentCoopAllowanceCapability()}
                    onChange={(newValue) =>
                      handleCoopAllowanceCapabilityChange(
                        newValue,
                        values,
                        setFieldValue
                      )
                    }
                    styles={S.SelectStyle}
                  />
                  <S.DeleteCoopAllowanceButtonContainer>
                    <IconButton
                      aria-label="deletecoopallowance"
                      title="Delete"
                      disabled={!currentCoopAllowance}
                      onClick={() =>
                        handleDeleteCoopAllowance(values, setFieldValue)
                      }
                    >
                      <DeleteForeverIcon />
                    </IconButton>
                  </S.DeleteCoopAllowanceButtonContainer>
                </Box>
              </>
            ) : undefined}

            <S.DialogActions>
              <S.ModalButton
                colorOption="stroke"
                label="CANCEL"
                onClick={() => close()}
              />
              <S.ModalButton
                colorOption="black"
                label="SAVE"
                onClick={() => {
                  validateForm().then((errors) => {
                    setTouched(setNestedObjectValues(errors, true))
                  })
                  submitForm()
                }}
              />
            </S.DialogActions>
          </S.DialogForm>
        )}
      </Formik>
    </>
  )
}

export { DealerForm }
