/* eslint-disable @typescript-eslint/no-explicit-any */
import * as S from './styles'

import {
  Dealer,
  DealerChannel,
  DealerResponse,
  CoopAllowance,
} from '@monorepo/interfaces'
import { Pagination, SectionTeamMembersList } from '../..'
import React, { useCallback, useEffect, useState, useRef } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core'

import { PageSpinner, Spinner, SearchTerm } from '@monorepo/components'
import { RegistrationUserResponse } from '../Registration'
import {
  httpUserInfo,
  useChannel,
  useDebounce,
  useQueryParams,
  KeycloakHelper,
  httpDialogDirect,
  ChannelHelper,
  DealerHelper,
  ReactSelectOptionType,
  isArrayEqual,
  isString,
} from '@monorepo/infra'
import axios from 'axios'
import {
  LogItemType,
  LogType,
  UserActivityLogData,
  log,
} from '../../../services/log.service'
import { useKeycloak } from '@react-keycloak/web'
import { SelectableDealer } from '../../organisms/SectionTeamMembersList'

type TeamMemebersProps = {
  profile: RegistrationUserResponse | null
  isAdmin: boolean
  dialog: {
    showEditDialog: boolean
    showCreateDialog: boolean
    showDeleteDialog: boolean
    setShowEditDialog: (toggle: boolean) => void
    setShowCreateDialog: (toggle: boolean) => void
    setShowDeleteDialog: (toggle: boolean) => void
  }
}

interface CreateDealer {
  user: {
    firstName: string
    lastName: string
    email: string
    groups: string[]
    enabled: boolean
  }
  dealer: {
    hasCoop: boolean
    channels?: string[]
    associatedMasterDealers: [
      {
        masterDealerId?: string
        channels?: string[]
        dealerTypes?: string[]
      }
    ]
    coopAllowances?: CoopAllowance[]
  }
}

export interface DealerForm {
  firstName: string
  lastName: string
  email: string
  channels: string[]
  types: string[]
  status: ReactSelectOptionType
  hasCoop: boolean
  userId: string
  dealerId: string
  coopAllowances?: CoopAllowance[]
}

const TeamMembers: React.FC<TeamMemebersProps> = ({
  isAdmin,
  profile,
  dialog,
}) => {
  const [paginationURL, setPaginationURL] = useState('page=1&pageSize=20')
  const [pagination, setPagination] = useState({
    totalCount: 1,
    page: 1,
    pageSize: 20,
    totalPages: 2,
  })
  const [dealers, setDealers] = useState<Dealer[] | null>()
  const masterDealerId =
    profile?.dealer?.associatedMasterDealers[0]?.masterDealerId
  const masterDealerIdURL = masterDealerId
    ? `masterDealerId=${masterDealerId}`
    : ''
  const [error, setError] = useState('')
  const [principalEmails, setPrincipalEmails] = useState<string[] | undefined>()

  const handlePageChange = useCallback((pageNumber: number) => {
    setPaginationURL(`page=${pageNumber}&pageSize=20`)
  }, [])

  const { selectedChannel, selectedMasterDealerId } = useChannel()
  const { keycloak } = useKeycloak()
  const searchURLKey = 's'
  const queryParams = useQueryParams()
  const [search, setSearch] = useState(queryParams.get(searchURLKey) ?? '')
  const debouncedSearch = useDebounce(search, 1000)

  const [loading, setLoading] = useState(false)
  const previousApiUrl = useRef<string | undefined>(undefined)

  const [selectedDealerIds, setSelectedDealerIds] = useState<string[]>([])
  const [isSelectAll, setIsSelectAll] = useState<boolean>(false)
  const [isDeleteDealersDealogOpen, setIsDeleteDealersDealogOpen] =
    useState<boolean>(false)

  const handleUserActivity = (data: DealerForm) => {
    const userChanges: UserActivityLogData = {
      userId: data.userId,
      changes: [],
    }

    const previusData = dealers?.find((d) => d.dealer._id === data.dealerId)
    if (previusData?.dealer.hasCoop !== data.hasCoop) {
      userChanges.changes.push({
        key: 'hasCoop',
        previusValue: Boolean(previusData?.dealer.hasCoop),
        currentValue: data.hasCoop,
      })
    }
    if (
      !isArrayEqual(
        previusData?.dealer.coopAllowances,
        data.coopAllowances,
        DealerHelper.isCoopAllowanceEqual
      )
    ) {
      userChanges.changes.push({
        key: 'coopAllowances',
        previusValue: Array.isArray(previusData?.dealer.coopAllowances)
          ? `[${previusData?.dealer.coopAllowances
              .map((item) => `{${DealerHelper.getCoopAllowanceText(item)}}`)
              .join()}]`
          : typeof previusData?.dealer?.coopAllowances,
        currentValue: Array.isArray(data.coopAllowances)
          ? `[${data.coopAllowances
              .map((item) => `{${DealerHelper.getCoopAllowanceText(item)}}`)
              .join()}]`
          : typeof data.coopAllowances,
      })
    }
    if (!previusData?.groups.includes(data.status.value)) {
      userChanges.changes.push({
        key: 'group',
        previusValue: `${previusData?.groups}`,
        currentValue: data.status.label,
      })
    }
    if (
      previusData?.dealer.associatedMasterDealers[0].channels
        .toString()
        .toLowerCase() !== data.channels.toString().toLowerCase()
    ) {
      Object.values(DealerChannel).filter((d) => {
        if (
          !previusData?.dealer.associatedMasterDealers[0].channels?.includes(
            d
          ) &&
          data?.channels?.includes(d)
        ) {
          userChanges.changes.push({
            key: d,
            previusValue: false,
            currentValue: true,
          })
        }
        if (
          previusData?.dealer.associatedMasterDealers[0].channels?.includes(
            d
          ) &&
          !data?.channels?.includes(d)
        ) {
          userChanges.changes.push({
            key: d,
            previusValue: true,
            currentValue: false,
          })
        }
      })
    }
    if (userChanges.changes.length > 0) {
      log(
        {
          type: LogType.USER_ACTIVITY,
          itemType: LogItemType.EVENT,
          masterDealerId: selectedMasterDealerId,
          channel: selectedChannel,
          userActivity: userChanges,
        },
        KeycloakHelper.getToken(keycloak)
      )
    }
  }

  const handleCreateUser = async (data: DealerForm) => {
    const dealer: CreateDealer = {
      user: {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        groups: [data.status.value],
        enabled: true,
      },
      dealer: {
        hasCoop: data.hasCoop,
        associatedMasterDealers: [
          {
            masterDealerId,
            channels: data.channels,
            dealerTypes: data.types,
          },
        ],
        coopAllowances: data.coopAllowances,
      },
    }
    const userExists = await httpUserInfo.get({
      url: `/dealer/${data.email}/exists`,
    })
    if (userExists.data === true) {
      setError(
        `That user already exists for another master dealer. Please use a different email address.`
      )
    } else {
      httpUserInfo
        .post({ url: '/dealer', data: dealer })
        .then(() => {
          addDialogDirectUser(data)
            .then(() => {
              setError('')
              dialog.setShowCreateDialog(false)
              getData(true)
            })
            .catch()
        })
        .catch()
    }
  }

  const handleDeleteUser = async (userId: string, dealerId: string) => {
    try {
      await httpUserInfo.delete({ url: `/dealer/${dealerId}` })
      await httpUserInfo.delete({ url: `/user/${userId}` })
    } catch (err) {
      console.error(err)
    } finally {
      dialog.setShowDeleteDialog(false)
      getData(true)
    }
  }

  const addDialogDirectUser = async (data: DealerForm) => {
    const addUserDto = {
      companyName: '',
      emailAddress: data.email.trim(),
      channel: data.channels.join('|').toUpperCase(),
      corpId: ChannelHelper.getMasterDealerId(
        masterDealerId as string,
        selectedChannel
      ),
      multiCorpId: data.channels
        .map((c) =>
          ChannelHelper.getMasterDealerId(
            masterDealerId as string,
            c.toUpperCase()
          )
        )
        .join('|'),
      address1: 'address1',
      addressee: '',
      city: 'Palo Alto',
      zip: '94301',
      state: 'CA',
      phoneNumber: '',
      attention: '',
      firstName: data.firstName,
      lastName: data.lastName,
    }

    await httpDialogDirect.post({ url: '/AddCustomer', data: addUserDto })
  }

  const handleEditUser = async (data: DealerForm) => {
    try {
      const dealerData = {
        hasCoop: data.hasCoop,
        associatedMasterDealers: [
          {
            masterDealerId,
            channels: data.channels,
            dealerTypes: data.types,
          },
        ],
        userEmail: data.email,
        coopAllowances: data.coopAllowances,
      }

      const userData = {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        groups: [data.status.value],
      }

      await httpUserInfo.patch({
        url: `/dealer/${data.dealerId}`,
        data: dealerData,
      })

      await httpUserInfo.patch({
        url: `/user/${data.userId}`,
        data: userData,
      })
      handleUserActivity(data)
      dialog.setShowEditDialog(false)
      getData(true)
    } catch (err) {
      console.error(err)
    }
  }

  const handleClearSearch = () => {
    setSearch('')
  }

  const getData = (isReload?: boolean) => {
    const searchURL =
      debouncedSearch.length > 2
        ? `search=${encodeURIComponent(debouncedSearch)}`
        : ''
    if (masterDealerIdURL) {
      const apiURL = `/dealer?${paginationURL}&withCoopAllowance=true&${masterDealerIdURL}&${searchURL}`

      if (!isReload && previousApiUrl.current === apiURL) {
        return
      }

      previousApiUrl.current = apiURL
      setLoading(true)
      httpUserInfo
        .get<DealerResponse>({
          url: apiURL,
        })
        .then((response) => {
          const { totalCount, page, pageSize, totalPages, data } = response.data

          setPagination({
            totalCount,
            page,
            pageSize,
            totalPages,
          })
          setDealers(getSelectedDealers(data, selectedDealerIds))
          setLoading(false)
        })
        .catch((err) => {
          setLoading(false)
        })
    }
  }

  useEffect(() => {
    getData()
  }, [masterDealerIdURL, paginationURL, debouncedSearch])

  useEffect(() => {
    getDealerUniqueEmails()
  }, [])

  const getDealerUniqueEmails = () => {
    axios
      .get('/United/DealerUniqueEmails', {
        baseURL: process.env.REACT_APP_TX_BASE_URL,
      })
      .then((response) => {
        setPrincipalEmails(response?.data?.DealerEmails)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getSelectedDealers = (
    dealers: Dealer[],
    selectedDealerIds: string[]
  ): SelectableDealer[] => {
    return dealers.map((item) => ({
      ...item,
      selected: selectedDealerIds.indexOf(item._id as string) !== -1,
    }))
  }

  const selectAll = (selected: boolean) => {
    if (!selected) {
      return
    }

    const tempSelectedDealerIds = dealers?.reduce((acc: string[], item) => {
      acc.push(item._id as string)
      return acc
    }, [])
    setSelectedDealerIds(tempSelectedDealerIds as string[])

    setDealers(
      getSelectedDealers(dealers as Dealer[], tempSelectedDealerIds as string[])
    )

    setIsSelectAll(true)
  }

  const selectOne = (id: string, selected: boolean) => {
    const tempSelectedDealerIds = [...selectedDealerIds]
    const idx = tempSelectedDealerIds.indexOf(id)
    if (selected) {
      if (idx === -1) {
        tempSelectedDealerIds.push(id)
      }
    } else {
      if (idx !== -1) {
        tempSelectedDealerIds.splice(idx, 1)
      }
    }
    setSelectedDealerIds(tempSelectedDealerIds)

    const tempSelectedDealers = getSelectedDealers(
      dealers as Dealer[],
      tempSelectedDealerIds
    )
    setDealers(tempSelectedDealers)

    setIsSelectAll(tempSelectedDealers.every((item) => item.selected))
  }

  const handleRemoveSelectedClick = () => {
    if (!selectedDealerIds.length || !isAdmin) {
      return
    }

    setIsDeleteDealersDealogOpen(true)
  }

  const closeDeleteDealersDialog = () => {
    setIsDeleteDealersDealogOpen(false)
  }

  const handleDeleteUsersClickAsync = async () => {
    if (!Array.isArray(dealers) || !dealers.length || !isAdmin) {
      return
    }

    const dealersToDelete = (dealers as SelectableDealer[]).filter(
      (item) =>
        item.selected &&
        isString(item.email) &&
        !!item.email?.trim() &&
        !DealerHelper.isPrincipal(item, principalEmails)
    )

    try {
      await deleteDealersAsync(dealersToDelete)
    } catch (error) {
      console.log(error)
    } finally {
      setIsDeleteDealersDealogOpen(false)
      getData(true)
    }
  }

  const deleteDealersAsync = async (dealers?: Dealer[]) => {
    if (!Array.isArray(dealers) || !dealers.length) {
      return
    }

    try {
      const requests: Promise<any>[] = []

      dealers.forEach((item) => {
        requests.push(
          httpUserInfo.delete({ url: `/dealer/${item.dealer._id}` })
        )
        requests.push(httpUserInfo.delete({ url: `/user/${item._id}` }))
      })

      await Promise.all(requests)
    } catch (error) {
      console.log(error)
    }
  }

  if (!dealers) return <PageSpinner />

  return (
    <S.Container>
      <S.ToolbarContainer>
        <S.SelectAllContainer hidden={!isAdmin}>
          <input
            type="checkbox"
            checked={isSelectAll}
            onChange={(e) => selectAll(e.target.checked)}
          />
          <S.SelectAllLabel>Select all</S.SelectAllLabel>
          <S.SelectAllButton onClick={handleRemoveSelectedClick}>
            Remove
          </S.SelectAllButton>
        </S.SelectAllContainer>
        <S.SearchContainer>
          <S.Search
            placeholder={'Search Team Members'}
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
          <SearchTerm searchTerm={search} onClear={handleClearSearch} />
        </S.SearchContainer>
      </S.ToolbarContainer>
      {!!loading ? (
        <Spinner spinnerSize={8} />
      ) : (
        <>
          <SectionTeamMembersList
            isAdmin={isAdmin}
            dealers={dealers}
            dialog={dialog}
            loading={!dealers}
            createDealer={handleCreateUser}
            deleteDealer={handleDeleteUser}
            editDealer={handleEditUser}
            error={error}
            principalEmails={principalEmails}
            selectable={isAdmin}
            selectOne={selectOne}
          />
          <Pagination
            onPageChange={handlePageChange}
            totalCount={pagination.totalCount}
            page={pagination.page}
            pageSize={pagination.pageSize}
            totalPages={pagination.totalPages}
          />
        </>
      )}
      <Dialog
        aria-label="DeleteDealersDialog"
        open={isDeleteDealersDealogOpen}
        onClose={closeDeleteDealersDialog}
      >
        <DialogTitle aria-label="DeleteDealersDialogTitle">
          {'Are you sure you want to delete the users?'}
        </DialogTitle>
        <DialogContent aria-label="DeleteDealersDialogContent">
          <DialogContentText>
            {(dealers as SelectableDealer[])
              .filter(
                (item) =>
                  isString(item.email) && !!item.email?.trim() && item.selected
              )
              .map((item) => item.email?.trim()?.toUpperCase())
              .join(', ')}
          </DialogContentText>
          <DialogActions>
            <Button onClick={closeDeleteDealersDialog}>CANCEL</Button>
            <Button onClick={handleDeleteUsersClickAsync}>Delete users</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </S.Container>
  )
}

export default TeamMembers
